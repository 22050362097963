<template>
  <div
    v-loading='loading'
    element-loading-text='数据加载中……'
    element-loading-spinner='el-icon-loading'>
    <div v-if='searchCondition.ym&&searchCondition.artist_id'>
      <el-tabs type='border-card' class='platforms-tab' :stretch='true' :tab-position='tabPosition'
               style='height: auto;' v-model='tabName' @tab-click='handleClick'>
        <template v-for='(platform,indx) in platforms'>
          <el-tab-pane :label='platform.name' :name='`${platform.code}`' :key='`${platform.code}_${indx}`'>
                <span slot='label'>
                  <div style='display: block;height: auto'>
                      <el-avatar shape='square' :size='30' :src='platform.logo_url' @error='true'>
                                  <img src='https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png' />
                      </el-avatar>
                    <br />
                     <el-tooltip class='item' effect='dark' :content='`${ platform.name}`' placement='right'>
                        <span class='platform_name'>{{
                            platform.name && platform.name.length > 2 ? platform.name.substring(0, 2) : platform.name
                          }}</span>
                      </el-tooltip>
                  </div>
                </span>
            <div style='min-height: 50%'>
              <div class='default-table tableBox'>
                <el-table
                  :data='tableData'
                  style='width: 100%'
                  :key='searchCondition.artist_id'
                  class='default-table'
                  :row-class-name='rowClassName'
                  :highlight-current-row='true'
                  @row-click='selectRow'
                  :height='`100%`'
                  border>
                  <el-table-column
                    prop='time_day'
                    label='日期'
                    min-width='80' align='center'>
                    <template slot-scope='scope'>
                <span :title='`${scope.row.nickname}(${scope.row.group_name}/${scope.row.platform_name})`'>
                  {{ scope.row.time_day }}
                </span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop='group_name'
                    label='部门-组'
                    min-width='80' align='center'>
                  </el-table-column>
                  <el-table-column
                    prop='nickname'
                    label='红人昵称'
                    min-width='80' header-align='center' align='center' show-overflow-tooltip>
                    <template slot-scope='{row}'>
                      <span>{{ row.nickname || '-' }}</span>
                    </template>
                  </el-table-column>
                  <template v-for='(column,index) in columns'>
                    <el-table-column :prop='`${column.value}`' :key='index' :label='`${column.title}`'
                                     :min-width='`${column.width}`' align='right' header-align='center'>
                      <editable-cell v-if='row.editable'
                                     :show-input.sync='row.editable'
                                     slot-scope='{row}'
                                     v-model='row[column.value]'
                                     :placeholder="`${$utils.parseTime(row.time_day,'{d}')}日${column.title}`"
                                     @change='selectInput(row,column.value)' :is-input-number='true'>
                          <span slot='content'>
                            <span>{{ row[column.value] }}</span>
                            <i class='el-icon-edit float-right' v-if='showEditIcon'></i>
                          </span>
                      </editable-cell>
                      <template v-else slot-scope='{row}'>
                        <span>{{ row[column.value] }}</span>
                      </template>
                    </el-table-column>
                  </template>
                </el-table>
              </div>
            </div>
          </el-tab-pane>
        </template>
      </el-tabs>
    </div>

    <div v-if='logCondition.artist_id&&logCondition.platform_code&&logCondition.ym'
         style='margin-top: 20px;margin-bottom: 20px'>
      <TrendDataLog :search-condition='logCondition'></TrendDataLog>
    </div>
  </div>
</template>

<script>
import EditableCell from '@/components/EditableCell'
import { numberFormat } from '@/utils/utils'
import TrendDataLog from '@/pages/trend/TrendDataLog'

export default {
  name: 'TrendRecord',
  components: {
    EditableCell, TrendDataLog
  },
  props: {
    readOnly: {
      type: Boolean,
      default: () => {
        return false
      }
    }
  },
  computed: {},
  data() {
    return {
      logCondition: {
        artist_id: null,
        nickname: null,
        ym: null,
        platform_code: null,
        platform_name: ''
      },
      searchCondition: {},
      tblHeight: 500,
      showEditIcon: true,//是否显示编辑按钮
      loading: false,
      tableData: [],
      loadingStatus: false,
      tabPosition: 'left',
      tabName: '',
      platforms: [],//全部平台
      showPlatforms: [],//显示的平台
      offset: 0,
      activeNames: [],
      columns: [
        { value: 'total_num', title: '总粉丝数', width: 100 },
        { value: 'add_num', title: '新增粉丝数', width: 120 },
        { value: 'content_add_num', title: '内容增粉数', width: 120 },
        { value: 'promotion_add_num', title: '推广增粉数', width: 120 },
        { value: 'promotion_cost', title: '涨粉投放', width: 100 },
        { value: 'price', title: '粉丝单价', width: 100 },
        { value: 'ad_repair', title: '广告维护', width: 100 },
        { value: 'live_preheat', title: '直播预热', width: 100 },
        { value: 'add_works_num', title: '新增作品数', width: 100 },
        { value: 'total_works_num', title: '总作品数', width: 100 }
      ],
      pagingData: {
        is_show: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0
      },
      fields: [
        'total_num',
        'add_num',
        'content_add_num',
        'promotion_add_num',
        'promotion_cost',
        'price',
        'ad_repair',
        'live_preheat',
        'add_works_num',
        'total_works_num'
      ],
      currentRow: {},//当前选中的行
      currentColumn: {},//当前选中的列
      currentArtist: {}
    }
  },
  created() {
    const tblHeight = window.document.body.clientHeight
    // console.log('tbl-h', tblHeight)
    this.tblHeight = tblHeight - 200
    // this.getPlatforms()

  },
  mounted() {
    // 监听 ctrl+v键盘事件
    document.addEventListener('paste', this.getPasteText, false)
  },
  beforeDestroy() {
    document.removeEventListener('paste', this.getPasteText, false)
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    showEditCell(day) {
      let currDate = new Date()
      currDate.setDate(currDate.getDate() - 1)
      //昨天的为true
      let lastDay = `${currDate.getFullYear()}-${currDate.getMonth() + 1}-${currDate.getDate()}`
      lastDay = this.$utils.parseTime(lastDay, '{y}-{m}-{d}')
      // if (lastDay === day)
      //   console.log(`${day} == ${lastDay}`)
      // else {
      //   console.log(`${day} != ${lastDay}`)
      // }

      return lastDay === day
    },
    moneyFormat(value) { // 金额 格式化
      return numberFormat(value, 2, '.', ',', 'round')
    },
    numFormat(value) { // 数值 格式化
      return numberFormat(value, 0, '.', ',', 'round')
    },
    // 处理搜索条件
    handleSearchCondition() {
      let condition = {}

      if (this.searchCondition.artist_id) {
        condition.artist_id = this.searchCondition.artist_id
      }
      if (this.searchCondition.ym) {
        condition.ym = this.searchCondition.ym
      }
      if (this.tabName) {
        condition.platform_code = this.tabName
      }

      return condition
    },
    async show(query) {
      this.searchCondition = query
      await this.changeTabs()
      await this.initData()
    },
    async initData() {
      let searchCondition = this.handleSearchCondition()
      this.tableData = []
      if (searchCondition.ym && searchCondition.artist_id && searchCondition.platform_code) {
        this.logCondition.ym = searchCondition.ym
        this.loading = true
        //已录入的数据
        let { list } = await this.$api.getTrendDatumMonthList(searchCondition)
        list.map((item) => {
          item.editable = this.showEditCell(item.time_day)
        })
        // console.log('list', list)
        this.tableData = list || []

        // console.log(this.tableData)
        this.loading = false
      }

    },
    //追加一行
    // appendLastRow(days, ym) {
    //   this.tableData = []
    //   for (let i = 0; i < days; i++) {
    //     let time_day = ym + '/' + (i + 1)
    //     let row = {
    //       'time_day': time_day,
    //       'group_id': this.searchCondition.group_id,
    //       'group_name': this.searchCondition.group_name,
    //       'artist_id': this.searchCondition.artist_id,
    //       'nickname': this.searchCondition.nickname,
    //       'platform_code': this.searchCondition.platform_code,
    //       'platform_name': this.searchCondition.platform_name,
    //       'total_num': '',
    //       'add_num': '',
    //       'content_add_num': '',
    //       'promotion_add_num': '',
    //       'promotion_cost': '',
    //       'price': '',
    //       'add_works_num': '',
    //       'total_works_num': ''
    //     }
    //     this.tableData.push(row)
    //   }
    //
    // },
    //保存单元格数据
    async saveCell(row, field) {
      let data = {}
      if (row.id) {
        data['id'] = row.id
        data[field] = row[field]
        data._current_field = field
      } else {
        data = { ...row, ...{ _current_field: field } }
      }

      let id = await this.$api.saveTrendDatum(data)
      if (id) {
        row.id = id
      } else {
        row[field] = null
        this.$notify.error('保存失败！')
      }
    },
    selectInput(row, field) {
      if (row[field] && row[field].length > 0)
        this.saveCell(row, field)
      else {
        row[field] = 0
        this.saveCell(row, field)
      }
    },
    rowClassName({ row, rowIndex }) {
      // 把每一行的索引放进row.id
      row.index = rowIndex + 1
      // row.editable = false
      // try {
      //   let lastDayTimestamp = this.getLastDays(1)
      //   let time_day = row.time_day
      //   if (time_day.indexOf('-') > -1) {
      //     let arr = time_day.split('-')
      //     let day = new Date(arr[0], arr[1] - 1, arr[2])
      //     let timestamp = day.getTime()
      //     let diff = timestamp - lastDayTimestamp
      //     if (diff > 0) {
      //       row.editable = false
      //     } else {
      //       row.editable = true
      //     }
      //   } else {
      //     row.editable = false
      //   }
      // } catch (e) {
      //   row.editable = true
      // }


    },
    getLastDays(days) {
      //几天前的时间
      let day1 = new Date()
      day1.setTime(day1.getTime() - 24 * 60 * 60 * 1000 * days)
      return day1.getTime()
    },
    //获取选中单元格信息
    selectRow(row, column) {
      this.currentRow = row
      this.currentColumn = column
      if (!row.editable) {
        const index = this.tableData.indexOf(row)
        let editable = !row.editable
        this.$set(this.tableData[index], 'editable', editable)
      }

    },
    // 获取粘贴文本处理：若有多列数据，则询问确认粘贴开始列
    getPasteText(event) {
      let clipboardData = (event.clipboardData || window.clipboardData)
      const text = clipboardData.getData('text')
      let arrText = text.trim().split(/ [(\r\n)\r\n] /).join().split('\t') // 以转行符切割文本字符串
      if (arrText.length > 1) {
        //当前列的字段
        let field = this.currentColumn.property
        let label = this.currentColumn.label || '未知'
        let startIndex = this.fields.indexOf(field)
        let fields = this.fields
        let pasteText = arrText.join('\t')
        let confirmMsg = `整行列粘贴：[${pasteText}]，是否确认?`
        //计算从哪列开始填充
        if (startIndex > -1) {
          //若选中的是可填充列，则从选中列开始往后填充，否则，填充整列
          let len = arrText.length
          fields = this.fields.slice(startIndex, len + startIndex)
          confirmMsg = `从列【${label}】开始粘贴：[${pasteText}]，是否确认?`
        }

        this.$confirm(confirmMsg, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          try {
            this.saveRow(fields, arrText, this.currentRow)
            // clipboardData.setData('text', '')
          } catch (e) {
            console.log('粘贴err:', e)

          }

        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          })
        })
      }
    },
    async saveRow(fields, arrText, row) {
      //粘贴之后请求剪切板
      navigator.clipboard.writeText('')
      const index = this.tableData.indexOf(row)
      for (let i = 0; i < fields.length; i++) {
        let f = fields[i]
        this.tableData[index][f] = arrText[i].trim()
        // if (this.tableData[index].id) {
        //   await this.saveCell(this.tableData[index], f)
        // } else
        //   await this.saveCell(this.tableData[index], f)
      }
      this.$notify.info('数据保存中...')
      for (let i = 0; i < fields.length; i++) {
        let f = fields[i]
        // this.tableData[index][f] = arrText[i].trim()
        await this.saveCell(this.tableData[index], f)

      }
      this.$notify.success('数据已保存，请核对数据准确性')

    },
    handleClick(tab) {
      //切换平台触发
      this.tabName = tab.name
      this.logCondition.platform_code = tab.name
      this.logCondition.platform_name = tab.label

      this.initData()
    },
    async changeTabs() {
      if (this.searchCondition.artist_id) {
        this.logCondition.artist_id = this.searchCondition.artist_id
        let { list } = await this.$api.getArtistOwnedPlatforms(this.searchCondition.artist_id)
        if (list) {
          this.platforms = list
          if (list[0]) {
            let firstPlatform = list[0]
            this.handleClick({ name: firstPlatform.code, label: firstPlatform.name })
          }

        }
      }
    }
  }
}
</script>

<style scoped>
.tableBox {
  height: 80vh;
}
</style>
